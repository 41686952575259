import React from "react";
import Card from "./Card";
import { IoMdLogIn } from "react-icons/io";

const UserGuide = ({ step, i }) => {
  return (
    <div className="comp8 w-full bg-white py-24">
      {step.istrue ? (
        <div className="md:max-w-[1215px] m-auto grid md:grid-cols-2 max-w-[600px] px-4 md:px-0">
          <div className="flex flex-col py-10 md:justify-around">
            <div>
              <p className="md:leading-[72px] mb-5 text-4xl font-bold">
                /{step.num}.
              </p>
              <p className="md:leading-[72px] mb-14 text-3xl font-bold">
                {step.heading}
              </p>
              {step.guide.map((proc, i) => (
                <Card key={i} title={proc} icons={<IoMdLogIn />} />
              ))}
            </div>
          </div>

          <div className="relative flex">
            <img
              src={step.image}
              alt="img"
              className="limg ml-[30%] w-[55%] h-[100%] "
            />
          </div>
        </div>
      ) : (
        <div className="md:max-w-[1215px] m-auto grid md:grid-cols-2 max-w-[600px] px-4 md:px-0">
          <div className="relative flex md:order-first order-last">
            <img
              src={step.image}
              alt="img"
              className="limg w-[55%] h-[100%] ml-[15%]"
            />
          </div>
          <div className="flex flex-col py-10 md:justify-around">
            <div>
              <p className="md:leading-[72px] mb-5 text-4xl font-bold">
                /{step.num}.
              </p>
              <p className="md:leading-[72px] mb-14 text-3xl font-bold">
                {step.heading}
              </p>
              {step.guide.map((proc, i) => (
                <Card key={i} title={proc} icons={<IoMdLogIn />} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserGuide;
