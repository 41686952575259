import React from "react";
import { useParams } from "react-router-dom";
import { BlogContents } from "../constants";

const BlogContent = () => {
  const { id } = useParams();
  const blog = BlogContents.find((blog, key) => blog.id === parseInt(id));

  return (
    <div className="comp12 w-full bg-[#e9e8e8] py-10">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] px-4 md:px-0">
        <div className="md:px-10">
          <img className="" src={blog.thumbnail} alt="Thumbnail" />
        </div>
        <div className="mt-10">
          <div className="">
            <h1 className="md:text-5xl text-3xl text-primary font-bold ">
              {blog.title}
            </h1>
            <div className="flex p-1 mt-5">
              <div className="p-1">
                <img
                  className="h-20 object-cover w-20 p-2 rounded-full"
                  src={blog.img}
                  alt=""
                />
              </div>
              <div className="p-2">
                <p>{blog.name}</p>
                <p>{blog.des}</p>
                <p>{blog.date}</p>
              </div>
            </div>
          </div>
          <div className="mt-14 md:text-lg text-justify">
            <p>{blog.intro}</p>
            {blog.div.map((division) => (
              <div className="my-4">
                <h3 className="text-2xl font-bold py-4">{division.heading}</h3>
                <p>{division.para}</p>
                <img
                  className="m-auto py-5 md:h-[450px] h-[300px]"
                  src={division.img}
                  alt=""
                />
              </div>
            ))}
          </div>
        </div>
        <div>
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default BlogContent;
