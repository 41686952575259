import React from "react";
import { quotationMark } from "../assets";

const FeedbackCard = ({ name, ans }) => {
  return (
    <div className="bg-[#ffffff] p-8 text-grey rounded-3xl shadow-xl border-black my-8 mx-2">
      <div className="flex  justify-between ">
        <div className="flex items-center font-heading font-medium text-lg gap-4">
          <p>{name}</p>
        </div>
        <img className="h-8" src={quotationMark} alt="question" />
      </div>

      <div className="py-8">
        <h2 className="text-lg line-clamp-5">{ans}</h2>
      </div>
    </div>
  );
};

export default FeedbackCard;
