import { useLayoutEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "./App.css";

import CookieBanner from "./components/CookieBanner";

import Home from "./Pages/Home";
import Policy from "./Pages/Policy";
import Posts from "./Pages/Posts";
import DownloadPage from "./Pages/DownloadPage";
import BlogsPage from "./Pages/BlogsPage";
import Blog from "./Pages/Blog";

function App() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [pathname]);

  return (
    <div className="">
      <CookieBanner />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Articles" element={<Posts />} />
        <Route path="/Privacy" element={<Policy />} />
        <Route path="/Download" element={<DownloadPage />} />
        <Route path="/Blogs" element={<BlogsPage />} />
        <Route path="/Blogs/:id" element={<Blog />} />
      </Routes>
    </div>
  );
}

export default App;
