import React from "react";
import UserGuide from "./UserGuide";
import { steps } from "../constants";

const Process = () => {
  return (
    <div className="comp5 md:justify-around">
      <p className="md:leading-[72px] mt-14 text-5xl font-heading font-medium text-center">
        How The App Works!!
      </p>
      {steps.map((step, i) => (
        <UserGuide step={step} key={i} />
      ))}
    </div>
  );
};

export default Process;
