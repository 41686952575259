import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  Navbar,
  Blogs,
  Footer,
  FooterBanner,
} from "../components";
import { Helmet } from "react-helmet-async";

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

const BlogsPage = () => {
  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);
  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);
  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  const ogData = {
    title: "Featured Blogs",
    description:
      "GoPool is your ultimate cabpooling and ridesharing app, offering safe, reliable, and cost-effective transportation solutions.",
    url: "https://gopool.app/Blogs",
    siteName: "Gopool",
    web: "website",
  };

  return (
    <>
      <Helmet>
        <title>{ogData.title}</title>
        <meta property="og:description" content={ogData.description} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:site_name" content={ogData.siteName} />
        <meta property="og:type" content={ogData.web} />

        <meta name="description" content={ogData.description} />

        <meta name="twitter:title" content={ogData.title} />
        <meta name="twitter:description" content={ogData.description} />
        <meta name="twitter:url" content={ogData.url} />

        <link rel="canonical" href="https://gopool.app/Blogs" />
      </Helmet>
      <div className="sticky top-0 z-[1000]">
        <Navbar />
      </div>
      <div
        ref={ref2}
        className={`transition-opacity ease-in duration-700 ${
          isVisible2 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Blogs />
      </div>
      <div
        ref={ref3}
        className={`transition-opacity ease-in duration-700 ${
          isVisible3 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Footer />
      </div>
      <div
        ref={ref4}
        className={`transition-opacity ease-in duration-700 ${
          isVisible4 ? "opacity-100" : "opacity-0"
        }`}
      >
        <FooterBanner />
      </div>
    </>
  );
};

export default BlogsPage;
