import React from "react";

const Privacy = () => {
  return (
    <div className="comp2 w-full bg-white text-[#000000] py-24">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] px-4 md:px-0 text-justify">
        <h1 className="font-bold font-heading text-4xl py-2 mb-10 text-primary">
          Privacy Policy
        </h1>
        <div className="mt-2">
          <p className="font-semibold underline">
            Last updated: December 21, 2022
          </p>
          <p className="py-2">
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </p>
          <p className="py-2">
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </p>
        </div>

        <div className="my-2">
          <h2 className="font-semibold font-heading text-2xl text-primary mt-4">
            Interpretation and Definitions
          </h2>
          <div className="mt-2">
            <p className="py-2">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
          </div>
          <div>
            <h3 className="py-2 font-semibold font-heading text-xl text-primary">
              Definitions
            </h3>
            <p className="py-2">For the purposes of this Privacy Policy:</p>
            <ol className="list-disc px-10">
              <li className="py-2">
                <span className="font-bold">Account </span> means a unique
                account created for You to access our Service or parts of our
                Service.
              </li>
              <li className="py-2">
                <span className="font-bold">Affiliate </span>
                means an entity that controls, is controlled by or is under
                common control with a party, where "control" means ownership of
                50% or more of the shares, equity interest or other securities
                entitled to vote for election of directors or other managing
                authority.
              </li>
              <li className="py-2">
                <span className="font-bold">Application </span>
                refers to GoPool, the software program provided by the Company.
              </li>
              <li className="py-2">
                <span className="font-bold">Company</span> (referred to as
                either "the Company", "We", "Us" or "Our" in this Agreement)
                refers to GoPool.
              </li>
              <li className="py-2">
                <span className="font-bold">Country refers to: </span>
                Maharashtra, India.
              </li>
              <li className="py-2">
                <span className="font-bold">Device</span> means any device that
                can access the Service such as a computer, a cellphone or a
                digital tablet.
              </li>
              <li className="py-2">
                <span className="font-bold">Personal Data</span> is any
                information that relates to an identified or identifiable
                individual.
              </li>
              <li className="py-2">
                <span className="font-bold">Service</span> refers to the
                Application.
              </li>
              <li className="py-2">
                <span className="font-bold">Service Provider</span> means any
                natural or legal person who processes the data on behalf of the
                Company. It refers to third-party companies or individuals
                employed by the Company to facilitate the Service, to provide
                the Service on behalf of the Company, to perform services
                related to the Service or to assist the Company in analyzing how
                the Service is used.
              </li>
              <li className="py-2">
                <span className="font-bold">Usage Data</span> refers to data
                collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example,
                the duration of a page visit).
              </li>
              <li className="py-2">
                <span className="font-bold">You</span> means the individual
                accessing or using the Service, or the company, or other legal
                entity on behalf of which such individual is accessing or using
                the Service, as applicable.
              </li>
            </ol>
          </div>
        </div>

        <div>
          <h1 className="py-4 font-semibold font-heading text-2xl text-primary">
            Collecting and Using Your Personal Data
          </h1>
          <div>
            <div>
              <p className="font-semibold font-heading text-l py-2 text-primary">
                Personal Data
              </p>
              <p className="py-2">
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to:
              </p>
              <ol className="list-disc px-10">
                <li className="py-2">First name and last name</li>
                <li className="py-2">Phone number</li>
                <li className="py-2">Usage Data</li>
                <li className="py-2">Aadhaar</li>
              </ol>
            </div>
            <div className="mt-4">
              <p className="font-semibold font-heading text-l py-2 text-primary">
                Usage Data
              </p>
              <p className="py-2">
                Usage Data is collected automatically when using the Service.
              </p>
              <p className="py-2">
                Usage Data may include information such as Your Device's
                Internet Protocol address (e.g. IP address), browser type,
                browser version, the pages of our Service that You visit, the
                time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data.
              </p>
              <p className="py-2">
                When You access the Service by or through a mobile device, We
                may collect certain information automatically, including, but
                not limited to, the type of mobile device You use, Your mobile
                device unique ID, the IP address of Your mobile device, Your
                mobile operating system, the type of mobile Internet browser You
                use, unique device identifiers and other diagnostic data.
              </p>
              <p className="py-2">
                We may also collect information that Your browser sends whenever
                You visit our Service or when You access the Service by or
                through a mobile device.
              </p>
            </div>
            <div>
              <p className="font-semibold font-heading text-l py-4 text-primary">
                Information Collected while Using the Application
              </p>
              <p className="py-2">
                While using Our Application, in order to provide features of Our
                Application, We may collect, with Your prior permission:
              </p>
              <ol className="list-disc px-10">
                <li className="py-2">Information regarding your location</li>
                <li className="py-2">
                  Pictures and other information from your Device's camera and
                  photo library
                </li>
              </ol>
              <p className="py-2">
                We use this information to provide features of Our Service, to
                improve and customize Our Service. The information may be
                uploaded to the Company's servers and/or a Service Provider's
                server or it may be simply stored on Your device.
              </p>
              <p className="py-2">
                You can enable or disable access to this information at any
                time, through Your Device settings.
              </p>
            </div>
          </div>
          <div className="mt-4">
            <h1 className="py-2 font-semibold font-heading text-l text-primary">
              Use of Your Personal Data
            </h1>
            <h2 className="py-2">
              The Company may use Personal Data for the following purposes:
            </h2>
            <ol className="list-disc px-10">
              <li className="py-2">
                <span className="font-bold">
                  To provide and maintain our Services, </span>
                including to monitor the usage of our Service.
              </li>
              <li className="py-2">
                <span className="font-bold">To manage Your Account: </span>
                To manage Your registration as a user of the Service. The
                Personal Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user.
              </li>
              <li className="py-2">
                <span className="font-bold">
                  For the performance of a contract: </span>
                The development, compliance and undertaking of the purchase
                contract for the products, items or services You have purchased
                or of any other contract with Us through the Service.
              </li>
              <li className="py-2">
                <span className="font-bold">To contact You: </span>
                To contact You by email, telephone calls, SMS, or other
                equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </li>
              <li className="py-2">
                <span className="font-bold">To provide You </span>
                with news, special offers and general information about other
                goods, services and events which we offer that are similar to
                those that you have already purchased or enquired about unless
                You have opted not to receive such information.
              </li>
              <li className="py-2">
                <span className="font-bold">To manage Your requests: </span>
                To attend and manage Your requests to Us.
              </li>
              <li className="py-2">
                <span className="font-bold">For business transfers: </span>
                We may use Your information to evaluate or conduct a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of Our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which Personal Data held by Us about our
                Service users is among the assets transferred.
              </li>
              <li className="py-2">
                <span className="font-bold">For other purposes: </span>
                We may use Your information for other purposes, such as data
                analysis, identifying usage trends, determining the
                effectiveness of our promotional campaigns and to evaluate and
                improve our Service, products, services, marketing and your
                experience.
              </li>
            </ol>
            <p className="py-2">
              We may share Your personal information in the following
              situations:
            </p>
            <ol className="list-disc px-10">
              <li className="py-2">
                <span className="font-bold">With Service Providers: </span>
                We may share Your personal information with Service Providers to
                monitor and analyze the use of our Service, to contact You.
              </li>
              <li className="py-2">
                <span className="font-bold">For business transfers: </span>
                We may share or transfer Your personal information in connection
                with, or during negotiations of, any merger, sale of Company
                assets, financing, or acquisition of all or a portion of Our
                business to another company.
              </li>
              <li className="py-2">
                <span className="font-bold">With Affiliates: </span>
                We may share Your information with Our affiliates, in which case
                we will require those affiliates to honor this Privacy Policy.
                Affiliates include Our parent company and any other
                subsidiaries, joint venture partners or other companies that We
                control or that are under common control with Us.
              </li>
              <li className="py-2">
                <span className="font-bold">With business partners: </span>
                We may share Your information with Our business partners to
                offer You certain products, services or promotions.
              </li>
              <li className="py-2">
                <span className="font-bold">With other users: </span>
                When You share personal information or otherwise interact in the
                public areas with other users, such information may be viewed by
                all users and may be publicly distributed outside.
              </li>
              <li className="py-2">
                <span className="font-bold">With Your consent: </span>
                We may disclose Your personal information for any other purpose
                with Your consent.
              </li>
            </ol>
          </div>
          <div className="mt-6">
            <h2 className="py-4 font-semibold font-heading text-l text-primary">
              Retention of Your Personal Data
            </h2>
            <p className="py-2">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </p>
            <p className="py-2">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </p>
          </div>
          <div className="mt-6">
            <h2 className="py-4 font-semibold font-heading text-l text-primary">
              Transfer of Your Personal Data
            </h2>
            <p className="py-2">
              Your information, including Personal Data, is processed at the
              Company's operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to and maintained on computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </p>
            <p className="py-2">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </p>
            <p className="py-2">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </p>
          </div>
          <div className="mt-6">
            <h2 className="py-4 font-semibold font-heading text-l text-primary">
              Delete Your Personal Data
            </h2>
            <p className="py-2">
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
            </p>
            <p className="py-2">
              Our Service may give You the ability to delete certain information
              about You from within the Service.
            </p>
            <p className="py-2">
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
            </p>
            <p className="py-2">
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </p>
          </div>
          <div className="mt-6">
            <h2 className="py-4 font-semibold font-heading text-xl text-primary">
              Disclosure of Your Personal Data
            </h2>
            <div className="px-3">
              <h3 className="py-2 font-semibold font-heading text-l">
                Business Transactions
              </h3>
              <p className="py-2">
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy.
              </p>
            </div>
            <div className="px-3">
              <h3 className="py-2 font-semibold font-heading text-l">
                Law enforcement
              </h3>
              <p className="py-2">
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency).
              </p>
            </div>
            <div className="px-3">
              <h3 className="py-2 font-semibold font-heading text-l">
                Other legal requirements
              </h3>
              <p className="py-2">
                The Company may disclose Your Personal Data in the good faith
                belief that such action is necessary to:
              </p>
              <ol className="list-disc px-10">
                <li className="py-2">Comply with a legal obligation</li>
                <li className="py-2">
                  Protect and defend the rights or property of the Company
                </li>
                <li className="py-2">
                  Prevent or investigate possible wrongdoing in connection with
                  the Service
                </li>
                <li className="py-2">
                  Protect the personal safety of Users of the Service or the
                  public
                </li>
                <li className="py-2">Protect against legal liability</li>
              </ol>
            </div>
          </div>
          <div className="mt-6">
            <h2 className="py-4 font-semibold font-heading text-xl text-primary">
              Security of Your Personal Data
            </h2>
            <p className="py-2">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </p>
          </div>
        </div>
        <div className="mt-6">
          <h2 className="py-4 font-semibold font-heading text-xl text-primary">
            Limitation of Liability
          </h2>
          <p className="py-2">
            IN NO EVENT WILL WE, OUR SUBSIDIARIES, OFFICERS, DIRECTORS,
            EMPLOYEES OR OUR SUPPLIERS, BE LIABLE TO YOU FOR ANY INCIDENTAL,
            CONSEQUENTIAL, OR INDIRECT DAMAGES (INCLUDING, BUT NOT LIMITED TO,
            DAMAGES FOR DELETION, CORRUPTION, LOSS OF DATA, LOSS OF PROGRAMS,
            FAILURE TO STORE ANY INFORMATION OR OTHER CONTENT MAINTAINED OR
            TRANSMITTED BY OUR SERVICES, SERVICE INTERRUPTIONS, OR FOR THE COST
            OF PROCUREMENT OF SUBSTITUTE SERVICES) ARISING OUT OF OR IN
            CONNECTION WITH GOPOOL, OUR SERVICES OR THIS AGREEMENT (HOWEVER
            ARISING, INCLUDING NEGLIGENCE) EVEN IF WE OR OUR AGENTS OR
            REPRESENTATIVES KNOW OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES.WE WILL NOT BE LIABLE FOR ANY DAMAGES, DIRECT, INDIRECT,
            INCIDENTAL AND/OR CONSEQUENTIAL, ARISING OUT OF THE USE OF GOPOOL OR
            THE SERVICES, INCLUDING, WITHOUT LIMITATION, TO DAMAGES ARISING OUT
            OF COMMUNICATING AND/OR MEETING WITH OTHER PARTICIPANTS OF GOPOOL OR
            THE SERVICES, OR INTRODUCED TO YOU VIA GOPOOL OR THE SERVICES. SUCH
            DAMAGES INCLUDE, WITHOUT LIMITATION, PHYSICAL DAMAGES, LOSS OF
            PRODUCTIVE TIME, BODILY INJURY, DEATH AND OR EMOTIONAL DISTRESS AND
            DISCOMFORT.
          </p>
          <p className="py-2">
            GoPool has no responsibility whatsoever for the actions or conduct
            of Travelers or Co-Travelers. GoPool has no obligation to intervene
            in or be involved in any way in disputes that may arise between
            “Cabpool Givers”, “Cabpool Takers”, or Third Parties. Responsibility
            for the decisions you make regarding providing or accepting ride
            sharing rest solely with You. It is each user's responsibility to
            take reasonable precautions in all actions and interactions with any
            party they may interact with through use of the services. GoPool may
            but has no responsibility to screen or otherwise evaluate potential
            users. Users understand and accept that GoPool has no control over
            the identity or actions of the “Cabpool Givers and “Cabpool Takers ,
            and GoPool requests that users exercise caution and good judgment
            when using the services “Cabpool Givers and “Cabpool Takers use the
            services at their own risk and evaluation.
          </p>
          <p className="py-2">
            Notwithstanding anything in the foregoing and without prejudice
            thereto, GoPool shall not be liable for any loss or damage arising
            as a result of:
          </p>
          <ol className="list-disc px-10">
            <li className="py-2">
              False, misleading, inaccurate or incomplete information being
              provided by a traveler
            </li>
            <li className="py-2">The cancellation of a Trip by a traveler</li>
            <li className="py-2">
              Any fraud, fraudulent misrepresentation or breach of duty or
              breach of any of these Conditions by a traveler before, during or
              after a Trip
            </li>
            <li className="py-2">
              GoPool will not be liable to any Member for any business,
              financial or economic loss or for any consequential or indirect
              loss such as lost reputation, lost bargain, lost profit, loss of
              anticipated savings or lost opportunity arising as a result of the
              services provided by, or enabled through, the GoPool platform
              (whether suffered or incurred as a result of the GoPools
              negligence or otherwise).
            </li>
          </ol>
          <p className="py-2">
            GoPool will not be liable to any Member in relation to any Ride.
            Given that GoPool service is limited to putting travelers in touch
            with each other and cannot oversee any Ride, Members accept that the
            limitations on the GoPools liability set out above are reasonable.
          </p>
        </div>

        <div className="mt-2">
          <h1 className="py-4 font-bold text-3xl text-primary">Release</h1>
          <p className="py-2">
            In the event that uou have a dispute with one or more users, You
            agree to release GoPool (and Our officers, directors, agents,
            subsidiaries, joint ventures and employees) from claims, demands and
            damages (actual and consequential) of every kind and nature, known
            and unknown, suspected and unsuspected, disclosed and undisclosed,
            arising out of or in any way connected to such disputes with other
            Users or to Your use of the GoPool Platform.
          </p>
        </div>
        <div className="mt-2">
          <h1 className="py-4 font-bold text-3xl text-primary">Breach</h1>
          <p className="py-2">
            Without limiting other remedies, We may terminate Your
            Participation, remove Your Information, warn Our community of Your
            actions, issue a warning, and refuse to provide Our services to You
            if:
          </p>
          <ol className="list-disc px-10">
            <li className="py-2">
              You breach this Agreement or the documents it incorporates by
              reference
            </li>
            <li className="py-2">
              We are unable to verify or authenticate any information You
              provide to Us
            </li>
            <li className="py-2">
              We believe that Your actions may cause financial loss or legal
              liability for You, Our users or Us, or subject GoPool or You or
              any other User to regulation by any state or local government or
              regulatory agency; or
            </li>
            <li className="py-2">
              if We suspect that You have engaged in fraudulent activity in
              connection with the GoPool Platform or the Services
            </li>
            <li className="py-2">
              The feedback/rating from other users is low
            </li>
          </ol>
        </div>
        <div className="mt-6">
          <h1 className="py-2 font-semibold font-heading text-2xl text-primary">
            Agreement to arbitrate all disputes and legal claims
          </h1>
          <p className="py-2">
            This Agreement shall be governed in accordance with laws of India.
            You and We agree that any legal disputes or claims arising out of or
            related to the Agreement (including but not limited to the use of
            the GoPool Platform and/or the Services, or the interpretation,
            enforceability, revocability, or validity of the Agreement, or the
            arbitrarily of any dispute), that cannot be resolved amicably by
            mutual discussions, shall be submitted to binding arbitration.
            Disputes will be resolved by arbitration, by a single arbitrator
            appointed upon mutual consent by the disputing Parties, in
            Bengaluru, India. If the Parties fail to appoint a sole arbitrator
            by mutual consent then arbitrators shall be appointed in accordance
            with the Indian Arbitration and Conciliation Act, 1996. The Courts
            at Bengaluru shall have exclusive jurisdiction to settle any
            disputes arising out of this Agreement.
          </p>
        </div>
        <div className="mt-6">
          <h1 className="py-2 font-semibold font-heading text-2xl text-primary">
            Dispute between/with travelers
          </h1>
          <p className="py-2">
            Notwithstanding anything contained in this agreement, GoPool may at
            its sole discretion provide its Members with an online service for
            resolving inter se disputes. This service is non-binding. GoPool is
            under no obligation to seek to resolve disputes and this Service is
            offered at GoPools sole discretion and may be withdrawn at any time.
            Without prejudice, disputes arising out the Ride between travelers
            or any other matter in relation to the use of the GoPool Platform
            between the Members shall be dealt with independently between such
            parties as deemed necessary.
          </p>
          <p className="py-2">
            Notwithstanding anything contained in this agreement, GoPool
            reserves its right to suspend or terminate the account of any
            Member, who is found to be in breach of any of the terms of this
            Agreement, and render the GoPool Platform inaccessible (temporarily
            or permanently, as the case maybe) with or without notice to such
            Member and without prejudice to such other legal claims that GoPool
            may choose to agitate against such Member in breach, before an
            appropriate forum, at its discretion.
          </p>
        </div>
        <div>
          <h1 className="py-2 font-semibold font-heading text-2xl mt-6 text-primary">
            Children's Privacy
          </h1>
          <p className="py-2">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p className="py-2">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
        </div>

        <div className="mt-6">
          <h1 className="py-2 font-semibold font-heading text-2xl text-primary">
            Links to Other Websites
          </h1>
          <p className="py-2">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p className="py-2">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
        </div>
        <div className="mt-6">
          <h1 className="py-2 font-semibold font-heading text-2xl text-primary">
            Changes to this Privacy Policy
          </h1>
          <p className="py-2">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="py-2">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p className="py-2">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>
        <div className="mt-6">
          <h1 className="py-2 font-semibold font-heading text-2xl text-primary">
            Contact Us
          </h1>
          <p className="py-2">
            If you have any questions about this Privacy Policy, You can Visit
            us:
          </p>
          <ol className="list-disc px-10">
            <li className="py-2">
              By visiting this page on our website:{" "}
              <a href="https://gopool.app/" className="text-blue-500 underline">
                https://gopool.app/
              </a>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
