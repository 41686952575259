import React from "react";

const Card = ({ icons, title }) => {
  return (
    <div className="category-card bg-white md:m-3 m-1 md:p-4 p-2 shadow-lg rounded-md flex items-center gap-4 justify-start border border-transparent text-primary border-emerald-100 group/edit">
      {icons}
      <div className="flex">
        <p className="md:max-w-[450px] max-w-[300px] md:text-xl text-lg font-medium ml-6">
          {title}
        </p>
      </div>
    </div>
  );
};

export default Card;
