import React from "react";
import { Link } from "react-router-dom";

import { webEvents, webEventsCategory } from "../constants";
import { trackEvent } from "../eventDispatchers/MixpanelEventDispatcher";
import { dispatchReactGAEvent } from "../eventDispatchers/GoogleAnalyticsEventDispatcher";

const FooterBanner = () => {
  return (
    <div className="comp10 w-full md:h-[60px] bg-black h-[95px]">
      <div className="md:max-w-[1215px] max-w-[600px] m-auto w-full h-full md:flex justify-between bg-transparent items-center md:px-0 text-white px-2 py-1">
        <div className="">
          <p className="text-[#ffffff] mt-1 text-md">
            © 2024 by GoPool Services Private Limited. Made with :)
          </p>
        </div>
        <div className="flex gap-4 my-2">
          <Link
            to="/Privacy"
            className="text-[#ffffff] text-l hover:underline"
            onClick={() => {
              dispatchReactGAEvent({
                category: webEventsCategory.NAVIGATION,
                action: webEvents.POLICY_PAGE_BUTTON_CLICKED,
              });
              trackEvent(webEvents.POLICY_PAGE_BUTTON_CLICKED, {});
            }}
          >
            <p className="text-[#ffffff] hover:text-gray-400 text-l ">
              Privacy Policy
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FooterBanner;
