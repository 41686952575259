import React from "react";
import { Link } from "react-router-dom";
import { logo, appstore, playstore } from "../assets";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";

import { webEvents, webEventsCategory } from "../constants";
import { trackEvent } from "../eventDispatchers/MixpanelEventDispatcher";
import { dispatchReactGAEvent } from "../eventDispatchers/GoogleAnalyticsEventDispatcher";

const Footer = () => {
  return (
    <div className="comp9 w-full bg-[#f0eeee] py-24">
      <div className="md:max-w-[1215px] m-auto grid md:grid-cols-3 grid-cols-1 max-[780px]:grid-cols-1 gap-8 max-w-[600px] py-5 px-4 md:px-0">
        <div className="text-center md:text-left col-span-1">
          <img src={logo} className="h-[50px] md:px-0 px-[33%]" alt="logo" />
          <h3 className="text-2xl font-bold mt-10">Contact Us</h3>
          <h3 className="py-2 text-[#121416]">
            Call :
            <a
              className="px-1 underline"
              target="_blank"
              rel="noopener noreferrer"
              href="tel:+91 8010251508"
              onClick={() => {
                dispatchReactGAEvent({
                  category: webEventsCategory.USER,
                  action: webEvents.CALL_US_BUTTON_CLICKED,
                });
                trackEvent(webEvents.CALL_US_BUTTON_CLICKED, {});
              }}
            >
              +91 8010251508
            </a>
          </h3>
          <h3 className="py-2 text-[#6D737A]">
            Hill Top, Ram Nagar <br></br> Nagpur, MH 440033
          </h3>
          <div className="md:ml-0 ml-16 flex justify-center  py-3 items-center text-center">
            <div>
              <h3 className="text-[#121416]">Email: akash.jadhav@gopool.app</h3>
            </div>
            <div className="m-auto ml-2">
              <a
                href="mailto:akash.jadhav@gopool.app"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="mail"
              >
                <div
                  className="p-2 cursor-pointer rounded-lg bg-primary"
                  onClick={() => {
                    dispatchReactGAEvent({
                      category: webEventsCategory.USER,
                      action: webEvents.EMAIL_ICON_CLICKED,
                    });
                    trackEvent(webEvents.EMAIL_ICON_CLICKED, {});
                  }}
                >
                  <div className="p-1 cursor-pointer rounded-lg bg-primary">
                    <MdOutlineMailOutline
                      size={20}
                      style={{ color: "#ffffff" }}
                    />
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="flex gap-4 py-4 md:px-0 px-[26%]">
            <a
              href={"https://www.facebook.com/gopool.app.page"}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <div
                className="p-4 cursor-pointer bg-primary rounded-xl"
                onClick={() => {
                  dispatchReactGAEvent({
                    category: webEventsCategory.USER,
                    action: webEvents.FACEBOOK_ICON_CLICKED,
                  });
                  trackEvent(webEvents.FACEBOOK_ICON_CLICKED, {
                    Profile: "GoPool",
                  });
                }}
              >
                <FaFacebookF size={25} style={{ color: "#ffffff" }} />
              </div>
            </a>
            <a
              href={"https://www.linkedin.com/company/gopool-app/"}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <div
                className="p-4 cursor-pointer bg-primary rounded-xl"
                onClick={() => {
                  dispatchReactGAEvent({
                    category: webEventsCategory.USER,
                    action: webEvents.LINKEDIN_ICON_CLICKED,
                  });
                  trackEvent(webEvents.LINKEDIN_ICON_CLICKED, {
                    Profile: "GoPool",
                  });
                }}
              >
                <FaLinkedinIn size={25} style={{ color: "#ffffff" }} />
              </div>
            </a>
            <a
              href={"https://www.instagram.com/gopool.app/"}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <div
                className="p-4 cursor-pointer bg-primary rounded-xl"
                onClick={() => {
                  dispatchReactGAEvent({
                    category: webEventsCategory.USER,
                    action: webEvents.INSTAGRAM_ICON_CLICKED,
                  });
                  trackEvent(webEvents.INSTAGRAM_ICON_CLICKED, {
                    Profile: "GoPool",
                  });
                }}
              >
                <FaInstagram size={25} style={{ color: "#ffffff" }} />
              </div>
            </a>
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div className="text-center"></div>
          <div className="text-center"></div>
        </div>

        <div className="text-center">
          <div
            className="py-5 px-2 text-[#6D737A]"
            onClick={() => {
              dispatchReactGAEvent({
                category: webEventsCategory.USER,
                action: webEvents.PLAYSTORE_BUTTON_CLICKED,
              });
              trackEvent(webEvents.PLAYSTORE_BUTTON_CLICKED, {});
            }}
          >
            <Link
              to="https://play.google.com/store/apps/details?id=com.cherryco.gopool.android"
              target="_blank"
            >
              <img
                className="md:h-24 h-16 hover:scale-105 object-fill duration-300 m-auto"
                src={playstore}
                alt="logo"
              />
            </Link>
          </div>
          <div
            className="py-5 px-2 text-[#6D737A]"
            onClick={() => {
              dispatchReactGAEvent({
                category: webEventsCategory.USER,
                action: webEvents.APPSTORE_BUTTON_CLICKED,
              });
              trackEvent(webEvents.APPSTORE_BUTTON_CLICKED, {});
            }}
          >
            <Link
              to="https://play.google.com/store/apps/details?id=com.cherryco.gopool.android"
              target="_blank"
            >
              <img
                className="md:h-24 h-16 hover:scale-105 duration-300 object-fill m-auto"
                src={appstore}
                alt="logo"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
