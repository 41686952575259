import React from "react";

const TopBanner = () => {
  return (
    <div className="w-full bg-secondary h-[90px] md:h-[60px]">
      <div className="md:max-w-[1215px] max-w-[600px] w-full h-full pt-3 m-auto text-2xl font-fontRegular justify-center bg-transparent items-center text-black text-center px-3">
        India's 1st Community-Building & Airport Fare-Sharing Platform
      </div>
    </div>
  );
};

export default TopBanner;
