import React, { useState, useEffect } from "react";
import { imageSlide } from "../constants";

const Banner = () => {
  const [currentState, setCurrentState] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentState === 3) {
        setCurrentState(0);
      } else {
        setCurrentState(currentState + 1);
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [currentState]);

  return (
    <div
      className={" flex w-full comp3  relative bg-black"}
    >
      <div className="w-full flex justify-center items-center h-full m-auto gap-4 md:px-0">
        <div className="md:w-[100%] w-[100%] banimg flex h-full">
          <img
            className="md:py-0 md:hidden w-full rounded-md h-full"
            src={`${imageSlide[currentState].url2}`}
            alt=""
          />
          <img
            className="hidden rounded-md  md:block md:py-0 w-full h-full"
            src={`${imageSlide[currentState].url}`}
            alt=""
          />
        </div>

        {/* <div className="md:w-[250px] w-[120px] absolute z-[998] text-primary md:top-[92%] md:left-[47%] left-[38%] top-[92%]">
          <div className="flex pt-10 justify-start block">
            {imageSlide.map((imageSlide, currentState) => (
              <span
                className="w-[50px] h-[12px] ml-[10px] rounded-[10px] cursor-pointer shadow-md"
                onClick={() => {
                  setCurrentState(currentState);
                }}
                key={currentState}
              ></span>
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
