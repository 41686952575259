import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;

  useEffect(() => {
    if (measurementId) {
      const timer = setTimeout(() => {
        setShowBanner(true);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [measurementId]);

  const handleAccept = () => {
    if (measurementId) {
      ReactGA.initialize(measurementId);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
    setShowBanner(false);
  };

  const handleReject = () => {
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Reject"
          cookieName="GoPoolCookieConsent"
          style={{
            background: "#e1ecef",
            borderRadius: "0.5rem",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            color: "#000000",
            padding: "0.7rem",
            display: "flex",
            height: "210px",
            width: "350px",
            position: "fixed",
            right: "0px",
            bottom: "0px",
            left: "unset",
          }}
          buttonStyle={{
            backgroundColor: "#5247FF",
            color: "white",
            padding: "0.5rem 1rem",
            borderRadius: "0.375rem",
            fontWeight: "medium",
            border: "none",
          }}
          declineButtonStyle={{
            backgroundColor: "#333333",
            color: "white",
            padding: "0.5rem 1rem",
            borderRadius: "0.375rem",
            fontWeight: "medium",
            border: "none",
          }}
          expires={30}
          enableDeclineButton
          onAccept={handleAccept}
          onDecline={handleReject}
          containerClasses="absolute right-0 mr-3 mb-3"
        >
          <p className="font-bold text-xl">We use cookies</p>
          <p className="text-sm mt-2">
            We use cookies to improve your experience on our website and analyze
            our web traffic. For more information, see our{" "}
            <Link to="/Privacy" className="text-primary underline">
              Privacy Policy
            </Link>
            .
          </p>
        </CookieConsent>
      )}
    </>
  );
};

export default CookieBanner;
