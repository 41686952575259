import React from "react";
import Slider from "react-slick";
import FeedbackCard from "./FeedbackCard";
import { feedbacks } from "../constants";

const Feedback = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="comp6 w-full bg-white py-20">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] px-4 md:px-0">
        <div className="py-4">
          <p className="py-3 text-4xl  font-heading font-medium">
            Customer's <span className="text-[#5247FF]">Feedback</span>
          </p>
          <p className="text-[#6D737A]">
            Discover what our happy customers are saying about GoPool.
          </p>
        </div>

        <Slider {...settings}>
          {feedbacks.map((feedback, i) => (
            <FeedbackCard key={i} name={feedback.name} ans={feedback.ans} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Feedback;
