import {
  register,
  findtravelers,
  akash,
  sunil,
  nitish,
  chetan,
  kush,
  fatima,
  moneycontrol,
  banner1,
  banner2,
  banner3,
  banner4,
  banner5,
  banner6,
  banner7,
  banner8,
  toi,
  curlyTales,
  yourStory,
  cnbc,
  moneyControlThumbnail,
  toiThumbnail,
  curlyTalesThumbnail,
  yourStoryThumbnail,
} from "../assets";

export const users = [
  {
    TotalUsers: "29,000",
    connections: "5,000",
    grv: "90,000",
    co2: "40",
  },
];
export const feedbacks = [
  {
    name: "Mohit Gupta",
    ans: `"Interface is intuitive and excellent. I was able to find the co-passanger at that time . Verified user and calling them in no time is great feature. 
    As this is a new app, would recommend to make app changes to ensure pre booking before boarding flight to Bangalore."`,
  },
  {
    name: "Sharik Ahmad",
    ans: `"I just wanted to say that I love your app GoPool The interface is so intuitive and easy to use. Recently I used this app and got the fellow passenger which reduced the fare to half amount. Overall, I think your app is great and I really appreciate the hard work that you and your team have put into it. Thank you for creating such a useful tool."`,
  },
  {
    name: "Swastik Roy",
    ans: `"I was lucky enough to stumble across “GoPool” ride sharing app. If you're staying far away from Bangalore Airport and you don't mind sharing a ride with someone of your vicinity and split the bills, this is the right platform for you. You can select your destination and connect with your potential co passenger hassle free."`,
  },
];

export const steps = [
  {
    istrue: true,
    num: "1",
    heading: "Register",
    image: register,
    guide: [
      "Register/Login via mobile number.",
      "Update your personal details.",
      "Add a nice profile picture.",
      "Update first name, last name, gender and age.",
    ],
  },
  {
    istrue: false,
    num: "2",
    heading: "Find travelers",
    image: findtravelers,
    guide: [
      "Add your destination.",
      "You will see number of people traveling to same location.",
      "Request for a call, discuss, meet, and ride together.",
      "Save money! Simple as that.",
    ],
  },
];

export const AllBlogs = [
  {
    id: 1,
    thumbnail: moneyControlThumbnail,
    topic: "Gopool",
    title:
      "Meet the startup that turns airport rides into cheap Ola-Uber cabpools",
    content:
      "Interface is intuitive and excellent. I was able to find the co-passanger at that time . Verified user and calling them in no time is great feature.",
    date: "19 July 2024",
  },
  {
    id: 2,
    thumbnail: moneyControlThumbnail,
    topic: "Gopool",
    title:
      "Meet the startup that turns airport rides into cheap Ola-Uber cabpools",
    content:
      "Interface is intuitive and excellent. I was able to find the co-passanger at that time . Verified user and calling them in no time is great feature.",
    date: "19 July 2024",
  },
  {
    id: 3,
    thumbnail: moneyControlThumbnail,
    topic: "Gopool",
    title:
      "Meet the startup that turns airport rides into cheap Ola-Uber cabpools",
    content:
      "Interface is intuitive and excellent. I was able to find the co-passanger at that time . Verified user and calling them in no time is great feature.",
    date: "19 July 2024",
  },
];

export const BlogContents = [
  {
    id: 1,
    thumbnail: banner1,
    title: "Revolutionizing Airport Commutes in Bengaluru: Introducing GoPool",
    img: akash,
    name: "Akash ",
    des: "Founder @GoPool",
    date: "Published on March 8, 2024",
    intro:
      "If you've ever found yourself navigating the bustling streets of Namma Bengaluru after a flight, you're well aware of the challenges that come with airport commutes. The traffic, the lengthy distance from the city center, and the soaring prices of airport cabs can take a toll on your travel experience. But fear not — I've got an exciting solution that not only transforms the ride from the airport into a delightful experience but also saves you a significant amount of money. Intrigued? Let me introduce you to GoPool, a game-changer in the realm of airport transportation.",
    div: [
      {
        heading: "The GoPool Experience",
        para: "Imagine a scenario: you've just landed in Bangalore, and instead of bracing yourself for an expensive solo cab ride that can cost anywhere between Rs 1,200 to Rs 2,500, there’s a more economical and eco-friendly alternative. Enter GoPool, a Bangalore-based startup that is reshaping the way we approach airport travel.",
        img: banner1,
      },
      {
        heading: "The GoPool Experience",
        para: "Imagine a scenario: you've just landed in Bangalore, and instead of bracing yourself for an expensive solo cab ride that can cost anywhere between Rs 1,200 to Rs 2,500, there’s a more economical and eco-friendly alternative. Enter GoPool, a Bangalore-based startup that is reshaping the way we approach airport travel.",
        img: banner1,
      },
      {
        heading: "The GoPool Experience",
        para: "Imagine a scenario: you've just landed in Bangalore, and instead of bracing yourself for an expensive solo cab ride that can cost anywhere between Rs 1,200 to Rs 2,500, there’s a more economical and eco-friendly alternative. Enter GoPool, a Bangalore-based startup that is reshaping the way we approach airport travel.",
        img: banner1,
      },
    ],
  },
  {
    id: 2,
    thumbnail: banner2,
    title: "Revolutionizing Airport Commutes in Bengaluru: Introducing GoPool",
    img: akash,
    name: "Akash Jadhav",
    des: "Founder @GoPool",
    date: "Published on March 8, 2024",
    intro:
      "If you've ever found yourself navigating the bustling streets of Namma Bengaluru after a flight, you're well aware of the challenges that come with airport commutes. The traffic, the lengthy distance from the city center, and the soaring prices of airport cabs can take a toll on your travel experience. But fear not — I've got an exciting solution that not only transforms the ride from the airport into a delightful experience but also saves you a significant amount of money. Intrigued? Let me introduce you to GoPool, a game-changer in the realm of airport transportation.",
    div: [
      {
        heading: "The GoPool Experience",
        para: "Imagine a scenario: you've just landed in Bangalore, and instead of bracing yourself for an expensive solo cab ride that can cost anywhere between Rs 1,200 to Rs 2,500, there’s a more economical and eco-friendly alternative. Enter GoPool, a Bangalore-based startup that is reshaping the way we approach airport travel.",
        img: banner2,
      },
      {
        heading: "The GoPool Experience",
        para: "Imagine a scenario: you've just landed in Bangalore, and instead of bracing yourself for an expensive solo cab ride that can cost anywhere between Rs 1,200 to Rs 2,500, there’s a more economical and eco-friendly alternative. Enter GoPool, a Bangalore-based startup that is reshaping the way we approach airport travel.",
        img: banner2,
      },
      {
        heading: "The GoPool Experience",
        para: "Imagine a scenario: you've just landed in Bangalore, and instead of bracing yourself for an expensive solo cab ride that can cost anywhere between Rs 1,200 to Rs 2,500, there’s a more economical and eco-friendly alternative. Enter GoPool, a Bangalore-based startup that is reshaping the way we approach airport travel.",
        img: banner2,
      },
    ],
  },
  {
    id: 3,
    thumbnail: banner3,
    title: "Revolutionizing Airport Commutes in Bengaluru: Introducing GoPool",
    img: akash,
    name: "Akash Jadhav",
    des: "Founder @GoPool",
    date: "Published on March 8, 2024",
    intro:
      "If you've ever found yourself navigating the bustling streets of Namma Bengaluru after a flight, you're well aware of the challenges that come with airport commutes. The traffic, the lengthy distance from the city center, and the soaring prices of airport cabs can take a toll on your travel experience. But fear not — I've got an exciting solution that not only transforms the ride from the airport into a delightful experience but also saves you a significant amount of money. Intrigued? Let me introduce you to GoPool, a game-changer in the realm of airport transportation.",
    div: [
      {
        heading: "The GoPool Experience",
        para: "Imagine a scenario: you've just landed in Bangalore, and instead of bracing yourself for an expensive solo cab ride that can cost anywhere between Rs 1,200 to Rs 2,500, there’s a more economical and eco-friendly alternative. Enter GoPool, a Bangalore-based startup that is reshaping the way we approach airport travel.",
        img: banner3,
      },
      {
        heading: "The GoPool Experience",
        para: "Imagine a scenario: you've just landed in Bangalore, and instead of bracing yourself for an expensive solo cab ride that can cost anywhere between Rs 1,200 to Rs 2,500, there’s a more economical and eco-friendly alternative. Enter GoPool, a Bangalore-based startup that is reshaping the way we approach airport travel.",
        img: banner3,
      },
      {
        heading: "The GoPool Experience",
        para: "Imagine a scenario: you've just landed in Bangalore, and instead of bracing yourself for an expensive solo cab ride that can cost anywhere between Rs 1,200 to Rs 2,500, there’s a more economical and eco-friendly alternative. Enter GoPool, a Bangalore-based startup that is reshaping the way we approach airport travel.",
        img: banner3,
      },
    ],
  },
];

export const features = [
  {
    thumbnail: moneyControlThumbnail,
    ans: "Meet the startup that turns airport rides into cheap Ola-Uber cabpools",
    site: "Money Control",
    logo: moneycontrol,
    link: "https://www.moneycontrol.com/technology/meet-the-startup-that-turns-airport-rides-into-cheap-ola-uber-carpools-article-12720323.html",
  },
  {
    thumbnail: toiThumbnail,
    ans: "This startup plans to turn Ola, Uber airport rides into budget-friendly cabpools, here's how - Times of India",
    site: "The Times of India",
    logo: toi,
    link: "https://timesofindia.indiatimes.com/technology/tech-news/this-startup-wants-to-turn-expensive-ola-uber-airport-rides-into-budget-friendly-carpools-heres-how/articleshow/110090721.cms",
  },
  {
    thumbnail: yourStoryThumbnail,
    ans: "YourStory Media on LinkedIn: #cabpooling #startup #GoPool",
    site: "Your Story",
    logo: yourStory,
    link: "https://www.linkedin.com/posts/yourstory-com_carpooling-startup-activity-7196086242919264256-KmW-/",
  },
  {
    thumbnail: curlyTalesThumbnail,
    ans: "What Is GoPool, The App That Lets Users Transform Expensive Airport Commutes Into Budget-Friendly Cabpool?",
    site: "Curly Tales",
    logo: curlyTales,
    link: "https://curlytales.com/what-is-gopool-the-app-that-lets-users-transform-expensive-airport-commutes-into-budget-friendly-carpool/",
  },
  {
    thumbnail: moneyControlThumbnail,
    ans: "What is GoPool, the app that lets users turn expensive airport rides into budget-friendly cabpooling?",
    site: "CNBC",
    logo: cnbc,
    link: "https://www.cnbctv18.com/business/startup/gopool-cheap-ola-uber-carpools-airport-cab-rides-startup-19410762.htm",
  },
];

export const TeamMembers = [
  {
    img: akash,
    Name: "Akash Jadhav",
    Des: "Founder & CEO",
    exp: "10+ years experience as Software Engineer and serial entrepreneur",
    ex1: "Previous Experience",
    ex2: "Unibuddy (SW Eng III) ",
    ex3: "Elucidata (Tech Lead)",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/akash-a-jadhav/",
    instagram: "",
  },
  {
    img: sunil,
    Name: "Sunil Dutt",
    Des: "Co-Founder & CMO",
    exp: "Serial entrepreneur - Launched Sweden's first Q-commerce company",
    ex1: "Previous Experience",
    ex2: "Vembla (Founder)",
    ex3: "Telenor (CEO Advisor)",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/sunil-dutt-3649821/",
    instagram: "",
  },
  {
    img: nitish,
    Name: "Nitish Purandare",
    Des: "Business Ops",
    exp: "7+ years experience from Mobility sector in India across scaleups",
    ex1: "Previous Experience",
    ex2: "Zoomcar (Sr BM)",
    ex3: "Yulu (1st Ops Hire)",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/nitish-purandare-814657154/",
    instagram: "",
  },
  {
    img: chetan,
    Name: "Chetan Patil",
    Des: "Business Ops",
    exp: "3+ years of experience from various industries",
    ex1: "Previous Experience",
    ex2: "Volkswagen India",
    ex3: "(Sr Service Advisor)",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/chetanpatil5577/",
    instagram: "",
  },
  {
    img: kush,
    Name: "Agrawal Kush",
    Des: "Frontend Developer",
    exp: "Passionate Software Engineering Intern specializing in Frontend Development with a strong foundation in React.",
    ex1: "Experience",
    ex2: "GoPool (SDE intern)",
    ex3: " ",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/agrawal-kush-9a5184212/",
    instagram: "",
  },
  {
    img: fatima,
    Name: "Fatima Badaoui",
    Des: "Full Stack Developer",
    exp: "Eager and adaptable Full Stack Web Developer fresh from completing a one year training at DCI.",
    ex1: "Experience",
    ex2: "POD E-Commerce Manager",
    ex3: "",
    facebook: "",
    linkedin: "https://www.linkedin.com/in/fatima-badaoui/",
    instagram: "",
  },
];

export const imageSlide = [
  {
    col: "primary",
    url: banner1,
    url2: banner5,
    title: "Find your airport travel buddy!",
    body1: "Make your airport journey social & fun.",
    body2: "Join GoPool now!",
  },
  {
    col: "third",
    url: banner2,
    url2: banner6,
    title: "Find your airport travel buddy!",
    body1: "Make your airport journey social & fun.",
    body2: "Join GoPool now!",
  },
  {
    col: "primary",
    url: banner3,
    url2: banner7,
    title: "Find your airport travel buddy!",
    body1: "Make your airport journey social & fun.",
    body2: "Join GoPool now!",
  },
  {
    col: "third",
    url: banner4,
    url2: banner8,
    title: "Find your airport travel buddy!",
    body1: "Make your airport journey social & fun.",
    body2: "Join GoPool now!",
  },
];

export const webEvents = {
  HOME_PAGE_BUTTON_CLICKED: "HOME_PAGE_BUTTON_CLICKED",
  ARTICLE_PAGE_BUTTON_CLICKED: "ARTICLES_PAGE_BUTTON_CLICKED",
  BLOG_PAGE_BUTTON_CLICKED: "BLOGS_PAGE_BUTTON_CLICKED",
  POLICY_PAGE_BUTTON_CLICKED: "POLICY_PAGE_BUTTON_CLICKED",
  DOWNLOAD_BUTTON_CLICKED: "DOWNLOAD_BUTTON_CLICKED",
  LINKEDIN_ICON_CLICKED: "LINKEDIN_ICON_CLICKED",
  INSTAGRAM_ICON_CLICKED: "INSTAGRAM_ICON_CLICKED",
  FACEBOOK_ICON_CLICKED: "FACEBOOK_ICON_CLICKED",
  FORM_SUBMIT_BUTTON_CLICKED: "FORM_SUBMIT_BUTTON_CLICKED",
  EMAIL_ICON_CLICKED: "EMAIL_ICON_CLICKED",
  CALL_US_BUTTON_CLICKED: "CALL_US_BUTTON_CLICKED",
  WHATSAPP_US_BUTTON_CLICKED: "WHATSAPP_US_BUTTON_CLICKED",
  PLAYSTORE_BUTTON_CLICKED: "PLAYSTORE_BUTTON_CLICKED",
  APPSTORE_BUTTON_CLICKED: "APPSTORE_BUTTON_CLICKED",
  ARTICLE_CLICKED: "ARTICLE_CLICKED",
  DOWNLOAD_PAGE_LANDED: "DOWNLOAD_PAGE_LANDED",
};

export const webEventsCategory = {
  USER: "User",
  NAVIGATION: "Navigation",
};
