import React, { useState } from "react";
import { logo, hamburger, close, logo3 } from "../assets";
import { Link } from "react-router-dom";
import { webEvents, webEventsCategory } from "../constants";
import { trackEvent } from "../eventDispatchers/MixpanelEventDispatcher";
import { dispatchReactGAEvent } from "../eventDispatchers/GoogleAnalyticsEventDispatcher";

const isIOS = () => {
  const userAgent = window.navigator.userAgent;
  return /iPad|iPhone|iPod/.test(userAgent);
};

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const handleClick = () => setToggle(!toggle);

  const handleDownload = () => {
    let url = "";
    if (isIOS()) {
      url = "https://apps.apple.com/us/app/go-pool/id1661427392";
    } else {
      url =
        "https://play.google.com/store/apps/details?id=com.cherryco.gopool.android";
    }
    window.open(url, "_blank");
  };

  return (
    <div className="comp1 w-full bg-black h-[80px] shadow-md">
      <div className="md:max-w-[1215px] max-w-[600px] m-auto w-full h-full flex justify-between bg-transparent items-center md:px-0 font-bold text-white px-4">
        <div>
          <Link
            onClick={() => {
              dispatchReactGAEvent({
                category: webEventsCategory.NAVIGATION,
                action: webEvents.HOME_PAGE_BUTTON_CLICKED,
              });
              trackEvent(webEvents.HOME_PAGE_BUTTON_CLICKED, {});
            }}
            className="flex gap-3"
            to="/"
          >
            <img
              src={logo3}
              className="h-[40px] w-[40px] rounded-xl cursor-pointer"
              alt="logo"
            />
            <img src={logo} className="h-[40px] cursor-pointer" alt="logo" />
          </Link>
        </div>

        <div className="hidden md:flex items-center select-none">
          <ul className="flex gap-5 text-xl ">
            <Link
              onClick={() => {
                dispatchReactGAEvent({
                  category: webEventsCategory.NAVIGATION,
                  action: webEvents.HOME_PAGE_BUTTON_CLICKED,
                });
                trackEvent(webEvents.HOME_PAGE_BUTTON_CLICKED, {});
              }}
              to="/"
            >
              <li className="cursor-pointer hover:text-[#ccd9e9]">Home</li>
            </Link>
            <Link
              onClick={() => {
                dispatchReactGAEvent({
                  category: webEventsCategory.NAVIGATION,
                  action: webEvents.ARTICLE_PAGE_BUTTON_CLICKED,
                });
                trackEvent(webEvents.ARTICLE_PAGE_BUTTON_CLICKED, {});
              }}
              to="/Articles"
            >
              <li className="cursor-pointer hover:text-[#ccd9e9]">Articles</li>
            </Link>
            <Link
              onClick={() => {
                dispatchReactGAEvent({
                  category: webEventsCategory.NAVIGATION,
                  action: webEvents.BLOG_PAGE_BUTTON_CLICKED,
                });
                trackEvent(webEvents.BLOG_PAGE_BUTTON_CLICKED, {});
              }}
              to="/Blogs"
              className="hidden"
            >
              <li className="cursor-pointer hover:text-[#ccd9e9]">Blogs</li>
            </Link>
          </ul>
        </div>

        <div className="hidden md:flex">
          <button
            onClick={() => {
              handleDownload();
              dispatchReactGAEvent({
                category: webEventsCategory.USER,
                action: webEvents.DOWNLOAD_BUTTON_CLICKED,
              });
              trackEvent(webEvents.DOWNLOAD_BUTTON_CLICKED, {});
            }}
            className="px-6 text-base py-3 rounded-md bg-primary text-white font-bold cursor-pointer hover:bg-[#8179f3]"
          >
            Download Now!
          </button>
        </div>

        <div className="md:hidden cursor-pointer" onClick={handleClick}>
          <img src={toggle ? close : hamburger} alt="toggle" />
        </div>
      </div>

      <div
        className={
          toggle
            ? "absolute z-10 p-4  bg-black w-full px-8 md:hidden border-b text-white"
            : "hidden"
        }
      >
        <ul className="select-none items-center">
          <Link
            onClick={() => {
              handleClick();
              dispatchReactGAEvent({
                category: webEventsCategory.NAVIGATION,
                action: webEvents.HOME_PAGE_BUTTON_CLICKED,
              });
              trackEvent(webEvents.HOME_PAGE_BUTTON_CLICKED, {});
            }}
            to="/"
          >
            <li className="p-4 text-center hover:bg-primary hover:text-black">
              Home
            </li>
          </Link>
          <Link
            onClick={() => {
              handleClick();
              dispatchReactGAEvent({
                category: webEventsCategory.NAVIGATION,
                action: webEvents.ARTICLE_PAGE_BUTTON_CLICKED,
              });
              trackEvent(webEvents.ARTICLE_PAGE_BUTTON_CLICKED, {});
            }}
            to="/Articles"
          >
            <li className="p-4 text-center  hover:bg-primary hover:text-black">
              Articles
            </li>
          </Link>
          <Link
            className="hidden"
            onClick={() => {
              handleClick();
              dispatchReactGAEvent({
                category: webEventsCategory.NAVIGATION,
                action: webEvents.BLOG_PAGE_BUTTON_CLICKED,
              });
              trackEvent(webEvents.BLOG_PAGE_BUTTON_CLICKED, {});
            }}
            to="/Blogs"
          >
            <li className="p-4 text-center  hover:bg-primary hover:text-black">
              Blogs
            </li>
          </Link>
          <div className="flex flex-col my-4 gap-4">
            <button
              onClick={() => {
                handleDownload();
                dispatchReactGAEvent({
                  category: webEventsCategory.USER,
                  action: webEvents.DOWNLOAD_BUTTON_CLICKED,
                });
                trackEvent(webEvents.DOWNLOAD_BUTTON_CLICKED, {});
              }}
              className="px-6 text-lg py-5 rounded-md bg-primary hover:bg-[#6d50ec] text-white font-bold cursor-pointer"
            >
              Download Now!
            </button>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
