import React from "react";
import {
  moneycontrol,
  medium,
  cnbc,
  toi,
  yourStory,
  curlyTales,
} from "../assets";
import Marquee from "react-fast-marquee";

const FeaturedIn = () => {
  return (
    <div className="w-full bg-gradient-to-r from-blue-500 to-purple-500 py-10">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] py-2 px-4 md:px-0">
        <p className="text-center font-bold text-white text-3xl md:pr-8 md:pt-0 pt-8">
          Featured In
        </p>
        <div className="md:flex justify-center items-center py-8 md:gap-8">
          <div className="flex flex-wrap gap-7 p-5 justify-center items-center">
            <Marquee speed={60} gradient={false}>
              <img
                src={moneycontrol}
                className="h-20 mr-8  w-42 bg-white p-2 rounded-md shadow-md"
                alt="MoneyControl"
              />
              <img
                src={cnbc}
                className="h-20 w-20 mr-8  bg-white p-2 rounded-md shadow-md"
                alt="CNBC"
              />

              <img
                src={toi}
                className="h-20 w-32 mr-8 bg-white p-2 rounded-md shadow-md object-cover"
                alt="TOI"
              />
              <img
                src={medium}
                className="h-20 w-36 mr-8 bg-white p-2 rounded-md shadow-md object-cover"
                alt="Medium"
              />
              <img
                src={yourStory}
                className="h-20 w-42 mr-8 bg-white p-2 rounded-md shadow-md object-cover"
                alt="YourStory"
              />
              <img
                src={curlyTales}
                className="h-20 w-42 mr-8 bg-white p-2 rounded-md shadow-md object-cover"
                alt="CurlyTales"
              />
              <img
                src={medium}
                className="h-20 w-36 mr-8 bg-white p-2 rounded-md shadow-md object-cover"
                alt="Medium"
              />
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedIn;
