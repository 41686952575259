import React from "react";
import Slider from "react-slick";
import TeamCard from "./TeamCard";
import { TeamMembers } from "../constants";

const Team = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="w-full bg-gray py-24">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] px-4 md:px-0">
        <div>
          <p className="md:leading-[72px] text-4xl font-heading font-semibold">
            Our <span className="text-primary">Team</span>
          </p>
        </div>
        <div>
          <Slider {...settings}>
            {TeamMembers.map((TeamMember, i) => (
              <TeamCard TeamMember={TeamMember} key={TeamMember} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Team;
