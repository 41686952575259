import React from "react";
import { startupindia } from "../assets";

const RecognizedBy = () => {
  return (
    <div className="w-full bg-white py-10">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] py-2 px-4 md:px-0">
        <p className="text-center font-heading font-bold text-black text-3xl">
          Recognized By
        </p>
        <img
          className="h-20 mt-10 m-auto duration-300"
          src={startupindia}
          alt="logo"
        />
      </div>
    </div>
  );
};

export default RecognizedBy;
