import React from "react";
import FeaturesPostCard from "../components/FeaturesPostCard";
import { features } from "../constants";

const Featuresposts = () => {
  return (
    <div className="comp11 w-full bg-[#e9e8e8] py-20">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] px-4 md:px-0">
        <div className="py-4 text-center">
          <h1 className="py-3 text-4xl font-heading font-medium">
            Feature <span className="text-[#5247FF]">Posts</span>
          </h1>
          <p className="text-[#6D737A] max-w-[800px] mx-auto">
          Explore our top stories and updates from the GoPool community.
          </p>
        </div>
        <div className="grid md:grid-cols-3 gap-2 mt-8">
          {features.map((feature, i) => (
            <FeaturesPostCard feature={feature} key={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Featuresposts;
