import ReactGA from "react-ga4";

export const dispatchReactGAEvent = ({ category, action, properties = {} }) => {
  if (!category || !action) {
    console.log("Category and action are required for GA events.");
    return;
  }

  const eventObject = {
    category: category,
    action: action,
    ...properties,
  };
  ReactGA.event(eventObject);
};
