import mixpanel from "mixpanel-browser";

const mixpanelKey = process.env.REACT_APP_MIXPANEL_KEY;

if (mixpanelKey) {
  mixpanel.init(mixpanelKey, {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
  });
}

export const trackEvent = (eventName, eventProps) => {
  if (mixpanelKey) {
    mixpanel.track(eventName, eventProps);
  }
};
