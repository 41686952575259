import React from "react";

import { webEvents, webEventsCategory } from "../constants";
import { trackEvent } from "../eventDispatchers/MixpanelEventDispatcher";
import { dispatchReactGAEvent } from "../eventDispatchers/GoogleAnalyticsEventDispatcher";

const FeaturesPostCard = ({ feature }) => {
  return (
    <div className="bg-white cursor-pointer flex flex-col justify-between text-black rounded-3xl h-[620px] mx-2">
      <a
        href={feature.link}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          dispatchReactGAEvent({
            category: webEventsCategory.NAVIGATION,
            action: webEvents.ARTICLE_CLICKED,
          });
          trackEvent(webEvents.ARTICLE_CLICKED, {
            Site: feature.site,
          });
        }}
      >
        <div className="flex justify-center items-center">
          <img
            className="rounded-xl object-cover h-[350px] p-2 mt-4"
            src={feature.thumbnail}
            alt="feature-thumbnail"
          />
        </div>

        <div className="flex flex-col justify-between h-[40%] p-8">
          <div className="py-4 flex ">
            <h3 className="text-2xl font-semibold line-clamp-2">
              {feature.ans}
            </h3>
          </div>

          <div className="flex items-center mt-6 space-x-4">
            <img className="h-10 w-22" src={feature.logo} alt="site-logo" />
          </div>
          <h1 className="text-l text-gray pt-2">{feature.site}</h1>
        </div>
      </a>
    </div>
  );
};

export default FeaturesPostCard;
