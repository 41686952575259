import React from "react";
import { Link } from "react-router-dom";
import { playstore, appstore } from "../assets";
import { users } from "../constants";

import { webEvents, webEventsCategory } from "../constants";
import { trackEvent } from "../eventDispatchers/MixpanelEventDispatcher";
import { dispatchReactGAEvent } from "../eventDispatchers/GoogleAnalyticsEventDispatcher";

const Trusted = () => {
  return (
    <div className="w-full py-[50px] bg-gray">
      <div className="md:max-w-[1215px] m-auto max-w-[600px] text-black px-4 md:px-0">
        <p className="text-center text-3xl font-heading font-bold">
          Trusted by over{" "}
          <span className="text-secondary text-4xl">
            {users[0].TotalUsers}+
          </span>{" "}
          Users.
        </p>
        <p className="text-center py-2 text-xl">
          Now available at Bangalore, Hyderabad, Pune and Goa
        </p>
        <div className="md:flex justify-center items-center pt-6 md:gap-8">
          <p className="text-center md:mb-0 mb-5 text-2xl font-bold">
            Try it yourself. Download now.
          </p>
          <div className="flex justify-center md:justify-start gap-4">
            <Link
              to="https://play.google.com/store/apps/details?id=com.cherryco.gopool.android"
              target="_blank"
              onClick={() => {
                dispatchReactGAEvent({
                  category: webEventsCategory.USER,
                  action: webEvents.PLAYSTORE_BUTTON_CLICKED,
                });
                trackEvent(webEvents.PLAYSTORE_BUTTON_CLICKED, {});
              }}
            >
              <img
                className="hover:scale-105 duration-300 h-14"
                src={playstore}
                alt="Play Store"
              />
            </Link>
            <Link
              to="https://apps.apple.com/us/app/go-pool/id1661427392"
              target="_blank"
              onClick={() => {
                dispatchReactGAEvent({
                  category: webEventsCategory.USER,
                  action: webEvents.APPSTORE_BUTTON_CLICKED,
                });
                trackEvent(webEvents.APPSTORE_BUTTON_CLICKED, {});
              }}
            >
              <img
                className="hover:scale-105 duration-300 h-14"
                src={appstore}
                alt="App Store"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trusted;
