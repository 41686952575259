import React from "react";
import { achievement } from "../assets";

import { SlPeople } from "react-icons/sl";
import { FaHandshake } from "react-icons/fa";
import { GiReceiveMoney } from "react-icons/gi";
import { MdOutlineCo2 } from "react-icons/md";
import { users } from "../constants";

const Achievement = () => {
  return (
    <div className="comp7 w-full bg-third py-24">
      <div className="md:max-w-[1215px] m-auto grid md:grid-cols-2 max-w-[600px] px-4 md:px-0">
        <div className="flex flex-col justify-center ">
          <p className="md:leading-[72px] text-4xl font-heading font-semibold">
            Our <span className="text-primary">Achievements</span>
          </p>
          <p className="text-[#6D737A]">
            Together, We're transforming the way India travels.
          </p>

          <div className="grid grid-cols-2 py-16">
            <div className="py-6 flex">
              <div className="p-4  bg-[#F0F7FF] rounded-xl">
                <SlPeople size={30} style={{ color: "#0075FD" }} />
              </div>
              <div className="px-3">
                <p className="md:text-3xl text-2xl">{users[0].TotalUsers}+</p>
                <p className="text-[#1d0c0c]">Registered users</p>
              </div>
            </div>
            <div className="py-6 flex">
              <div className="p-4  bg-[#E9F8F3] rounded-xl">
                <FaHandshake size={30} style={{ color: "#1A906B" }} />
              </div>
              <div className="px-3">
                <p className="md:text-3xl text-2xl">{users[0].connections}+</p>
                <p className="text-[#1d0c0c]">Successful connections</p>
              </div>
            </div>
            <div className="py-6 flex">
              <div className="p-4  bg-[#FFFAF5] rounded-xl">
                <GiReceiveMoney size={30} style={{ color: "#FFC27A" }} />
              </div>
              <div className="px-3">
                <p className="md:text-3xl text-2xl ">${users[0].grv}+</p>
                <p className="text-[#1d0c0c]">GRV savings through GoPool</p>
              </div>
            </div>
            <div className="py-6 flex">
              <div className="p-4  bg-[#FFEEF0] rounded-xl">
                <MdOutlineCo2 size={30} style={{ color: "#ED4459" }} />
              </div>
              <div className="px-3">
                <p className="md:text-3xl text-2xl">{users[0].co2}+ MT</p>
                <p className="text-[#1d0c0c]">Reduction in CO2 emissions</p>
              </div>
            </div>
          </div>
        </div>
        <div className="m-auto">
          <img
            src={achievement}
            className="m-auto md:order-last  order-first w-96 "
            alt="achievement"
          />
        </div>
      </div>
    </div>
  );
};

export default Achievement;
