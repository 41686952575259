import React from "react";
import { MdWhatsapp } from "react-icons/md";
import { IoIosContact } from "react-icons/io";
import Swal from "sweetalert2";

import { webEvents, webEventsCategory } from "../constants";
import { trackEvent } from "../eventDispatchers/MixpanelEventDispatcher";
import { dispatchReactGAEvent } from "../eventDispatchers/GoogleAnalyticsEventDispatcher";

const Contact = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(event.target);

    formData.append("access_key", process.env.REACT_APP_WEB3FORMS_KEY);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      Swal.fire({
        title: "Success!",
        text: "Message sent successfully!",
        icon: "success",
      });
      form.reset();
    }
  };
  return (
    <>
      <form onSubmit={onSubmit}>
        <div className="comp9 w-full bg-[#bccae1] py-14">
          <div className="md:max-w-[1215px] m-auto grid md:grid-cols-1 grid-cols-1 max-[780px]:grid-cols-1 gap-8 max-w-[600px] py-5 px-4 md:px-0">
            <div className="m-auto">
              <p className="md:leading-[72px] text-4xl mb-14 font-heading font-semibold">
                Contact <span className="text-primary">Support</span>
              </p>
            </div>
            <div>
              <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 m-auto md:grid-cols-4 md:w-[600px]">
                <div className="md:col-span-4 text-black">
                  <label className="text-xl" htmlFor="full_name">
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 text-black"
                    placeholder="Akash Kumar"
                    required
                  />
                </div>

                <div className="md:col-span-4 text-black">
                  <label className="text-xl" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 text-black"
                    placeholder="email@domain.com"
                    required
                  />
                </div>

                <div className="md:col-span-4 text-black">
                  <label className="text-xl" htmlFor="state">
                    Message
                  </label>
                  <textarea
                    rows="4"
                    name="message"
                    id="message"
                    className="border mt-1 rounded px-3 py-1 w-full bg-gray-50 text-black"
                    placeholder="Leave a Message"
                    required
                  ></textarea>
                </div>
                <button
                  className="md:col-span-4 bg-primary text-center hover:bg-blue-700 text-white text-l font-bold mt-6 rounded h-10"
                  onClick={() => {
                    dispatchReactGAEvent({
                      category: webEventsCategory.USER,
                      action: webEvents.FORM_SUBMIT_BUTTON_CLICKED,
                    });
                    trackEvent(webEvents.FORM_SUBMIT_BUTTON_CLICKED, {});
                  }}
                >
                  Submit
                </button>

                <div className="md:col-span-4">
                  <p className="text-center m-auto mt-4 text-xl font-bold">
                    Or
                  </p>
                  <div className="mt-4">
                    <div className="m-auto flex p-2 gap-6 justify-center">
                      <a
                        className="hover:scale-105 object-fill duration-300 w-40"
                        href="tel:+91 8010251508"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="mail"
                        onClick={() => {
                          dispatchReactGAEvent({
                            category: webEventsCategory.USER,
                            action: webEvents.CALL_US_BUTTON_CLICKED,
                          });
                          trackEvent(webEvents.CALL_US_BUTTON_CLICKED, {});
                        }}
                      >
                        <div className="p-3 m-auto cursor-pointer rounded-lg bg-primary">
                          <div className="flex">
                            <p className="text-white m-auto font-bold">
                              Contact Us
                            </p>

                            <IoIosContact
                              size={25}
                              style={{ color: "#ffffff" }}
                              className="mr-2"
                            />
                          </div>
                        </div>
                      </a>
                      <a
                        className="hover:scale-105 object-fill duration-300 w-40"
                        href="https://wa.me/918010251508?text=Hello How can I help you ?"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="whatsapp"
                        onClick={() => {
                          dispatchReactGAEvent({
                            category: webEventsCategory.USER,
                            action: webEvents.WHATSAPP_US_BUTTON_CLICKED,
                          });
                          trackEvent(webEvents.WHATSAPP_US_BUTTON_CLICKED, {});
                        }}
                      >
                        <div className="p-3 flex cursor-pointer rounded-lg bg-primary">
                          <p className="pr-2 m-auto text-white font-bold">
                            WhatsApp Us
                          </p>
                          <MdWhatsapp
                            className="mr-2"
                            size={25}
                            style={{ color: "#ffffff" }}
                          />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Contact;
