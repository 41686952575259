import React from "react";
import { useEffect, useState, useRef } from "react";
import { Navbar, BlogContent, Footer, FooterBanner } from "../components";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    });

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}

const Blog = () => {
  const { id } = useParams();

  const ref2 = useRef();
  const isVisible2 = useIsVisible(ref2);
  const ref3 = useRef();
  const isVisible3 = useIsVisible(ref3);
  const ref4 = useRef();
  const isVisible4 = useIsVisible(ref4);

  return (
    <>
      <Helmet>
        <title>GoPool - Your Ultimate Cabpooling and Ridesharing App</title>
        <meta
          name="description"
          content="GoPool is your ultimate cabpooling and ridesharing app, offering safe, reliable, and cost-effective transportation solutions."
        />
        <link rel="canonical" href={`https://gopool.app/Blogs/${id}`} />
      </Helmet>
      <div className="sticky top-0 z-[1000]">
        <Navbar />
      </div>
      <div
        ref={ref2}
        className={`transition-opacity ease-in duration-700 ${
          isVisible2 ? "opacity-100" : "opacity-0"
        }`}
      >
        <BlogContent />
      </div>
      <div
        ref={ref3}
        className={`transition-opacity ease-in duration-700 ${
          isVisible3 ? "opacity-100" : "opacity-0"
        }`}
      >
        <Footer />
      </div>
      <div
        ref={ref4}
        className={`transition-opacity ease-in duration-700 ${
          isVisible4 ? "opacity-100" : "opacity-0"
        }`}
      >
        <FooterBanner />
      </div>
    </>
  );
};

export default Blog;
