import React from "react";
import { useNavigate } from "react-router-dom";

const BlogsCard = ({ Blog }) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/blogs/${Blog.id}`)}
      className="bg-[#e9e8e8] cursor-pointer flex flex-col justify-between text-black h-[550px] mx-2"
    >
      <div className="m-4 cursor-pointer">
        <img
          src={Blog.thumbnail}
          className="w-full
             object-cover h-[250px]"
          alt=""
        />
        <h3 className="text-primary py-2 font-bold mt-3">{Blog.topic}</h3>
        <h3 className="font-bold mt-3">{Blog.title}</h3>
        <h3 className="line-clamp-3 py-1 text-gray-400 mt-3">{Blog.content}</h3>
        <div className="flex items-center mt-5">
          <div className="bg-primary p-2 rounded-md">
            <h3 className="text-black font-bold text-sm">{Blog.date}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsCard;
