import React from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { webEvents } from "../constants";
import { trackEvent } from "../eventDispatchers/MixpanelEventDispatcher";

const TeamCard = ({ TeamMember }) => {
  return (
    <div className="bg-[#ffffff] p-8 text-grey rounded-3xl shadow-xl border-black select-text my-8 mx-2 gap-[30px] xl:flex-row items-center max-h-[820px]">
      <div className="">
        <img
          src={TeamMember.img}
          className="m-auto md:order-last order-first rounded-lg bg-primary max-h-[400px] tabheight shadow-lg elevation-4"
          alt="achievement"
        />
      </div>

      <div className="flex-1 mt-4 text-center">
        <h3 className="h-10 text-2xl font-normal mb-[10px]">{TeamMember.Name}</h3>
        <div className="font-medium uppercase tracking-[2.24px] text-[#9ab4b7] mb-[20px]">
          {TeamMember.Des}
        </div>
        <div className="">
          <p className="font-light text-l mb-[26px]">{TeamMember.exp}</p>
          <p className="font-bold text-l mb-2">{TeamMember.ex1}</p>
          <p className="font-light text-l mb-[26px]">
            {TeamMember.ex2} <br />
            {TeamMember.ex3}{" "}
          </p>
        </div>
        <div className="flex items-center justify-center gap-4 py-4">
          <div
            className="p-4 cursor-pointer bg-[#E9F8F3] rounded-3xl"
            onClick={() => {
              trackEvent(webEvents.LINKEDIN_ICON_CLICKED, {
                Profile: TeamMember.Name,
              });
            }}
          >
            <a
              href={TeamMember.linkedin}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="LinkedIn"
            >
              <FaLinkedinIn size={25} style={{ color: "#4DC39E" }} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
